<!-- Version: 1.0.2 -->
<template>
  <b-row v-if="signedUser" align-v="center" align-h="end">
    <!-- Bejelentkezett alap adatai -->
    <b-col cols="12" sm="6" order="3" order-sm="1">
      <!-- Belépve, mint ... -->
      <b-row>
        <p class="eh-web-app-header-logged-in-text">
          {{ $t("header.loggedWith", { name: signedUser.Name }) }}
        </p>
      </b-row>
      <!-- Felület több szerepkör -->
      <b-row
        v-if="signedUser.Roles && signedUser.Roles.length > 1"
        align-v="center"
      >
        <!-- Felület -->
        <b-col cols="12" lg="2" class="eh-web-app-header-selector-label">
          {{ $t("header.usedRoleView") }}:
        </b-col>
        <!-- Felület választó -->
        <b-col cols="12" lg="10">
          <fp-select
            class="eh-web-app-header-selector"
            :items="signedUser.Roles"
            @change="changeRole"
            :value="signedUser.SelectedRole"
          >
            <template #element="role"
              >{{ $t(roleList[role.SimpleArrayToObjectArrayValue].Text) }}
            </template>
          </fp-select>
        </b-col>
      </b-row>
      <!-- Felület egy szerepkör -->
      <b-row v-else class="eh-web-app-header-static-selector">
        {{ $t("header.usedRoleView") }}:
        {{
          roleList[signedUser.SelectedRole]
            ? $t(roleList[signedUser.SelectedRole].Text)
            : null
        }}
      </b-row>
      <!-- Projekt választó -->
      <b-row
        align-v="center"
        v-if="
          signedUser.SelectedRole != 'Client' &&
          signedUser.Projects &&
          signedUser.Projects[signedUser.SelectedRole] &&
          signedUser.Projects[signedUser.SelectedRole].length > 0
        "
      >
        <!-- Projekt felirat -->
        <b-col cols="12" lg="2" class="eh-web-app-header-selector-label">
          {{ $t("header.usedProject") }}:
        </b-col>
        <!-- Projekt választó lista -->
        <b-col cols="12" lg="10">
          <fp-select
            class="eh-web-app-header-selector"
            valueKey="ProjectId"
            :items="signedUser.Projects[signedUser.SelectedRole]"
            :value="
              signedUser.SelectedProject
                ? signedUser.SelectedProject.ProjectId
                : null
            "
            @change="changeProject"
          >
            <template #element="e">
              {{
                e.ProjectName ? getTextByLanguage(e.ProjectName) : e.ProjectName
              }}
            </template>
          </fp-select>
        </b-col>
      </b-row>
    </b-col>
    <!-- Profil kép -->
    <b-col cols="12" sm="3" order="2">
      <fp-header-profile-picture-link
        :profileImg="profileImg"
        :profileFillingRate="profileFillingRate"
        :signedProfilePicture="signedProfilePicture"
      />
    </b-col>
  </b-row>
</template>
<script>
import { UserLogic } from "@/Logic/Backend/user";
import { UserProfileLogic } from "@/Logic/Backend/user-profile";
import { AttachmentLogic } from "@/Logic/Backend/attachment";

export default {
  name: "LoggedUser",
  props: {
    signedName: String,
    signedProfilePicture: String,
    changedProfileFillingRate: Object,
  },
  data() {
    return {
      signedUser: {},
      roleList: [],
      profileImg: null,
      profileFillingRate: null,
    };
  },
  watch: {
    signedName(input) {
      this.signedUser.Name = input;
    },
    "signedUser.Name": function () {
      UserLogic.saveLoginUser(this.signedUser);
    },
    changedProfileFillingRate(input) {
      this.profileFillingRate = input;
    },
  },
  methods: {
    getTextByLanguage(textObject) {
      const languages = Object.keys(textObject);
      if (languages.includes(this.$i18n.locale)) {
        return textObject[this.$i18n.locale];
      } else if (languages.includes("en")) {
        return textObject.en;
      } else {
        return textObject[languages[0]];
      }
    },
    //profilkép lekérdezés
    async getUserProfilePicture(profile) {
      //kérés indítás
      if (profile.PersonalProfile.ProfilePictureId) {
        const pictureDocuResponse = await AttachmentLogic.downloadAttachment(
          profile.PersonalProfile.ProfilePictureId
        );
        //sikeres kérés?
        if (!pictureDocuResponse.Code) {
          //kapott kép blobba mentés
          const imgBlob = await pictureDocuResponse.blob();
          //visszatérünk a blobból alakított url-lel
          return URL.createObjectURL(imgBlob);
        } else {
          this.$bvToast.toast(pictureDocuResponse.Message, {
            title: this.$t(
              "requestResponse.attachment.errorDownloadProfilePhoto"
            ),
            variant: "danger",
            solid: true,
            AutoHideDelay: 10000,
          });
          return require("@/Assets/Default/annonym-profil-icon.png");
        }
      }
      //sikertelen kép kéréskor, null-lal térünk vissza
      return require("@/Assets/Default/annonym-profil-icon.png");
    },
    //profil kitöltöttség chart beállítása
    setProfileFillingRate(userProfileResponse) {
      //kapott profilhoz tartozik kitöltöttségi mutató?
      if (
        (userProfileResponse.PersonalProfile.FillingRate ||
          userProfileResponse.PersonalProfile.FillingRate == 0) &&
        userProfileResponse.MedicalProfile &&
        (userProfileResponse.MedicalProfile.FillingRate ||
          userProfileResponse.MedicalProfile.FillingRate == 0)
      ) {
        //igen, eltároljuk a chart megjelenéshez szükséges adatokat
        this.profileFillingRate = {
          MedicalProfile: userProfileResponse.MedicalProfile.FillingRate,
          PersonalProfile: userProfileResponse.PersonalProfile.FillingRate,
        };
        //console.log("PROFILE_FILLING_RATE", this.profileFillingRate);
      }
    },
    //profilkép beállítása
    async setPrfoilePicture(userProfileResponse) {
      //van profilhoz tartozó file id megadva?
      if (userProfileResponse.PersonalProfile.ProfilePictureId) {
        //igen, kép letöltés, file id alapján, és url tárolása
        this.profileImg = await this.getUserProfilePicture(
          userProfileResponse.PersonalProfile.ProfilePictureId
        );
        /* //a tárolt url tásolása a store-ba
        this.$store.dispatch("changeUser", {
          Url: imgUrl,
        }); */
      } else {
        this.profileImg = require("@/Assets/Default/annonym-profil-icon.png");
        /* //nem, default kép url tárolása storeba
        this.$store.dispatch("changeUser", {
          Url: require("@/Assets/Default/annonym-profil-icon.png"),
        }); */
      }
    },
    //profil adatok kérés
    async getProfile() {
      //kérés indítása
      const userProfileResponse = await UserProfileLogic.getUserProfile();
      //sikeres kérés?
      if (!userProfileResponse.Code) {
        if (userProfileResponse.PersonalProfile.PreferredLanguage) {
          this.preferedLanguage =
            userProfileResponse.PersonalProfile.PreferredLanguage;
        }
        //profil kitöltöttségi chart adatok megadása
        this.setProfileFillingRate(userProfileResponse);
        //profilkép megjelenés kérés
        this.profileImg = await this.getUserProfilePicture(userProfileResponse);
      } else {
        this.$bvToast.toast(userProfileResponse.Message, {
          title: this.$t("requestResponse.basic.errorGetNamedData", {
            name: this.$t("components.header.errorAccountName"),
          }),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    changeRole(role) {
      if (this.signedUser.Projects[role]) {
        this.signedUser.SelectedProject = this.signedUser.Projects[role][0];
      } else {
        this.signedUser.SelectedProject = null;
      }

      UserLogic.saveLoginUser(this.signedUser);
      this.routerPushRoleHome(role);
    },
    routerPushRoleHome(role) {
      const selectedRoleElement = this.roleList[role];
      const currentRoleElement = this.roleList[this.signedUser.SelectedRole];
      if (selectedRoleElement && selectedRoleElement.Home) {
        this.signedUser.SelectedRole = role;
        UserLogic.saveLoginUser(this.signedUser);
        if (currentRoleElement.Home != selectedRoleElement.Home) {
          this.$router.push({ name: selectedRoleElement.Home });
        }
      }
    },
    checkSignedUser() {
      this.signedUser = UserLogic.getSignedUser();
      if (this.signedUser.SelectedProject) {
        this.$eventBus.$emit(
          "projectChanged",
          this.signedUser.SelectedProject.ProjectId
        );
      }
      if (this.signedUser.Roles.includes("Administrator")) {
        this.signedUser.Roles = Object.values(this.roleList).map(
          (role) => role.Value
        );
      }
      this.signedUser.Roles = this.signedUser.Roles.filter((r) =>
        this.$productSettings.AvailableRoleViews.includes(r)
      );
      UserLogic.saveLoginUser(this.signedUser);
    },
    changeProject(projectId) {
      const selectedProject = this.signedUser.Projects[
        this.signedUser.SelectedRole
      ].find((project) => project.ProjectId == projectId);
      this.signedUser.SelectedProject = selectedProject;
      UserLogic.saveLoginUser(this.signedUser);
      this.$emit("forceUpdate");
      this.$eventBus.$emit("projectChanged", projectId);
    },
  },
  //betöltéskor egyszer lefut
  async mounted() {
    this.roleList = this.$enums.UserRoleWithHome;
    this.checkSignedUser();

    //profil adatok betöltése
    await this.getProfile();
  },
};
</script>
<style scoped>
.role-list-element.current-user-role {
  border-color: var(--eh-secondary-5);
  background: var(--eh-secondary-1);
}

.role-list-element:hover {
  opacity: 0.5;
}

.role-list-element {
  cursor: pointer;
  border: solid 3px;
  border-radius: 10px;
  border-color: var(--eh-primary-5);
  background: var(--eh-primary-2);
  margin: 10px;
  padding: 10px;
}

#header-above-menu-logo-box {
  padding: 12px 0px;
}

#header-above-menu-logo {
  height: 50px;
}

.header-logged-user-text {
  /* text-align: end; */
  color: var(--eh-white);
  font-size: 20px;
  line-height: 20px;
}

.header-site-title {
  font-weight: 900;
  font-size: 20px;
  text-align: left;
  margin: 0px;
}

@media (max-width: 700px) {
  .header-logged-user-text {
    text-align: center;
  }

  .header-site-title {
    text-align: center;
  }
}
</style>
